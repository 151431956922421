/*.react-pdf__Page__canvas {
    border: 1px dotted #60C5FF;
}*/
.react-pdf__Page
{
    border: 1px dotted var(--colorPrimary);
}

.documentSignatureContainer {
    display: inline-block;
}
.documentSignatureContainer2 {
    display: inline-block;
    margin-left: 0;
    margin-bottom: 100px;
}

.react-draggable {
    display: inline-block;
}

.react-resizable {
    border: 2px dashed var(--colorPrimary);
}

.fix-bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
    .hide-in-phone {
        display: none;
    }
}
/* For mobile phones: */
@media only screen and (min-width: 768px) {
    .hide-in-pc {
        display: none;
    }
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .anticon{
    color: var(--colorPrimary)
}

.ant-menu-dark.ant-menu-vertical .ant-menu-item, .ant-menu-inline .ant-menu-item{
    padding-left: 10px;
}

.settingsButton {
    position: absolute;
    width: 62px; 
    height: 62px !important;
    left:0; 
    top:184px; 
    border-radius: 0 8px 8px 0 !important;
    z-index: 2;
}

.blink {
    animation: blinker 1s step-start infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

